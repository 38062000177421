var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"unread-group"},[_c('div',{staticClass:"border-xl border-top-xl-none"},[_vm._m(0),(!_vm.groupUnread.length)?_c('div',{staticClass:"text-center text-secondary py-6"},[_vm._v(" "+_vm._s(_vm.$t('common.message.no_unread'))+" ")]):_vm._e(),_vm._l((_vm.groupUnread),function(group,index){return _c('div',{key:index,staticClass:"pb-1",class:{ 'border-top': index, 'd-none': !group.count_unread }},[_c('div',{staticClass:"p-2"},[_c('div',{staticClass:"d-flex align-item-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              group.icon_image_small_path
                ? group.icon_image_small_path
                : _vm.noGroupAvatar
            ),expression:"\n              group.icon_image_small_path\n                ? group.icon_image_small_path\n                : noGroupAvatar\n            "}],staticClass:"w--8 w-xl--12 h--8 h-xl--12 img-cover rounded-pill me-2"}),_c('div',{staticClass:"flex-center"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                customClass: _vm.handleLongText(group.name).isLong
                  ? 'd-block'
                  : 'd-none'
              }),expression:"{\n                customClass: handleLongText(group.name).isLong\n                  ? 'd-block'\n                  : 'd-none'\n              }",modifiers:{"hover":true}}],staticClass:"text-line-clamp-1 fs-14 fs-xl-16 fwb hover-o-75",attrs:{"to":{ name: 'group-toppage', params: { groupId: group.id } },"title":group.name}},[_vm._v(" "+_vm._s(_vm.handleLongText(group.name).text)+" ")])],1)]),_c('div',{staticClass:"d-none d-xl-block border-top-xl border-secondary mt-2 mb-2 ms-13 me-4"}),_c('div',{staticClass:"d-flex justify-content-between ps-8 ps-xl-12 me-2"},[_c('span',{staticClass:"ms-2 flex-center"},[_vm._v(" "+_vm._s(_vm.$t('common.message.unread_post', { count: group.count_unread }))+" ")]),_c('button',{staticClass:"btn btn-outline-secondary-deep btn-light mt-n1 mt-xl-0 me-1 me-xl-7",on:{"click":function($event){return _vm.markReadAllGroup(index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_mark_as_read'))+" ")])])]),_vm._l((group.data),function(topicFile,childIndex){return _c('div',{key:childIndex,staticClass:"topic-file ms-xl-10 me-xl-1"},[(topicFile.is_topic)?_c('ListTopic',{attrs:{"topics":[topicFile],"disableCheckbox":true,"isFromHome":true,"groupId":group.id},on:{"subtractReadCount":function($event){return _vm.markReadGroup(index, childIndex)}}}):_c('SharedFolderList',{attrs:{"files":[topicFile],"disableCheckbox":true,"forGroupTop":true,"isFromHome":true,"groupId":group.id},on:{"subtractReadCount":function($event){return _vm.markReadGroup(index, childIndex)}}})],1)}),(
          _vm.getPageGroupData(group.id).page < _vm.getPageGroupData(group.id).maxPage
        )?_c('div',{staticClass:"text-center py-4 border-top-xl border-secondary mx-xl-6"},[_c('button',{staticClass:"btn btn-outline-secondary-deep",on:{"click":function($event){return _vm.loadmoreGroupData(index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_see_more'))+" ")])]):_vm._e()],2)}),(_vm.isLoading)?_c('div',{staticClass:"text-center py-4"},[_c('b-spinner',{attrs:{"label":"Spinning"}})],1):_vm._e(),(_vm.showLoadmore)?_c('div',{staticClass:"text-center py-4 border-top"},[_c('button',{staticClass:"btn btn-outline-secondary-deep text-nowrap",on:{"click":_vm.loadmoreGroup}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_see_more'))+" ")])]):_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-2 px-xl-4 pt-1 pe-4"},[_c('div',{staticClass:"border border-primary"})])}]

export { render, staticRenderFns }